<template>
  <div class="account-confirmation">
    <img v-if="status=='error'" src="images/cosmonaut-account-confirmation.png" alt="">

    <h2 v-if="status=='success'">{{$t('account_confirmation_title_success')}}</h2>
    <h2 v-if="status=='already'">{{$t('account_confirmation_title_already')}}</h2>
    <h2 v-if="status=='expired'">{{$t('account_confirmation_title_expired')}}</h2>
    <h2 v-if="status=='error'">{{$t('account_confirmation_title_error')}}</h2>

    <p v-if="status=='success' || status=='already'">{{$t('account_confirmation_text')}}</p>
    <p v-if="status=='expired'">{{$t('account_confirmation_text_expired')}}</p>
    <p v-if="status=='error'">{{$t('account_confirmation_text_error')}}</p>

    <a v-if="status=='success' || status=='already' || status=='expired'" href="https://portal.invoxia.com/start_track_app/" title="Invoxia account (GPS tracker)" target="new" data-linkto="">{{$t('return_to_app')}} (GPS)</a>
    <a v-if="status=='success' || status=='already' || status=='expired'" href="https://portal.invoxia.com/start_sdc_app/" title="Invoxia account (Pet Care)" target="new" data-linkto="">{{$t('return_to_app')}} (Pet Care)</a>

    <a v-if="status=='error'" href="https://invoxia.zendesk.com/hc/fr/requests/new" title="Invoxia account" target="new" data-linkto="">{{$t('contact_us')}}</a>
  </div>
</template>

<script>
export default {
  name: 'AccountConfirmation',
  data() {
    return {'status': 'success'}
  },
  mounted: function () {
     window.zE('webWidget', 'hide');
  },
  created: function () {
    let parameters = this.$route.query;
    if (parameters['status']) {
      let status = parameters['status'];
      if (status != 'success' && status != 'already' && status != 'error' && status != 'expired') {
        this.status = 'success';
      } else {
        this.status = status;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.account-confirmation {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5em;

  h2 {
    width: 90%;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
  }

  p {
    max-width: 90%;
    font-size: 1em;
    text-align: center;
  }

  a {
    width: 90%;
    font-weight: 600;
    font-size: 1em;
    text-decoration: none;
    text-align: center;
    color: white;
    background-color: #038FF9;
    border-radius: 14px;
    padding: 17px 0;
    transition: all .3s ease;

    &:hover {
      opacity: .8;
    }
  }
}
</style>